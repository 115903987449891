import { Member } from "interfaces/front/business";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { FrontMeService } from "../service/client/FrontMeService";

export function useFrontAuth(doRedirect = false): Member | undefined | null {
  const router = useRouter();
  const [member, setMember] = useState<Member | null>();
  useEffect(() => {
    const service = new FrontMeService();
    service.me().then(async (result) => {
      if (result.isSuccess()) {
        setMember(result.value);
      } else {
        setMember(null);
        if (doRedirect) {
          await router.replace({ pathname: "/login", query: { original: router.asPath } });
          await window.scrollTo(0, 0);
        }
      }
    });
  }, []);
  return member;
}
