import { createContext, useContext, useState, useEffect } from "react";
import { NextPage } from "next";
import { useFrontAuth } from "lib/hooks/useFrontAuth";
import { FrontMeService } from "lib/service/client/FrontMeService";
import { isClient, isServer } from "lib/util";
import { Member } from "interfaces/front/business";

export type FrontContextType = Member | undefined | null;

const FRONT_AUTH_CONTEXT_KEY = 'FRONT_AUTH_CONTEXT_KEY';

const FrontContext = createContext<FrontContextType>(undefined);

// FIXME: FrontContextTypeを拡張して格納する形式にしたい。
export function calcLoginStatus(context: FrontContextType) {
  return {
    isLoading: (context === undefined),
    isLoggedIn: (!!context),
    notLoggedIn: (context === null),
  };
}

export function useFrontAuthContext(): FrontContextType {
  const member = useContext(FrontContext);
  return member;
}

export type FrontAuthProps = {
  reloadFrontAuth: () => void;
  authMember: FrontContextType;
}
export type Options = {
  doRedirect: boolean;
}

export function getSessionFrontAuth(): FrontContextType {
  if (isServer()) return undefined;
  const context = window.sessionStorage.getItem(FRONT_AUTH_CONTEXT_KEY);
  return context ? (JSON.parse(context) as Member) : undefined;
}

export function setSessionFrontAuth(member: Member): void {
  if (isClient()) {
    const context = JSON.stringify(member);
    window.sessionStorage.setItem(FRONT_AUTH_CONTEXT_KEY, context);
  }
}

export function removeSessionFrontAuth(): void {
  if (isClient()) {
    window.sessionStorage.removeItem(FRONT_AUTH_CONTEXT_KEY);
  }
}

export function withFrontAuth<P extends FrontAuthProps>(ComposedComponent: NextPage<P>, option: Options = { doRedirect: false }): React.ComponentType<P> {
  // TODO: memberがsessionとapi取得によりdefined→undefined→definedとなるため不要に他処理のuseMemoが実行されてしまっている
  function WithFrontAuthWrapper(props: P): JSX.Element {
    const [member, setMember] = useState<FrontContextType>(getSessionFrontAuth());
    const initialMember = useFrontAuth(option.doRedirect);
    useEffect(() => {
      setMember(initialMember);
    }, [initialMember]);
    const reloadFrontAuth = (): void => {
      const service = new FrontMeService();
      service.me().then((result) => {
        if (result.isSuccess()) {
          setMember(result.value);
        }
      });
    }
    useEffect(() => {
      if (member) {
        setSessionFrontAuth(member);
      } else {
        removeSessionFrontAuth();
      }
    }, [member]);
    return (
      <FrontContext.Provider value={member}>
        <ComposedComponent {...props} reloadFrontAuth={reloadFrontAuth} authMember={member} />
      </FrontContext.Provider>
    )
  }
  return WithFrontAuthWrapper;
}

