import { BaseClientService, Error, ErrorV2, Result } from "../BaseClientService";
import { ServiceError } from "./FrontPasswordService";
import { PutQuery } from "./FrontMemberService";
import { FormikValues } from "formik";
import { SupportComment } from "./FrontProjectService";
import { CommentInput } from "components/front/mypage/order/SupportCommentEditModal";
import { Member, TippingComment } from "interfaces/front/business";

export class FrontMeService extends BaseClientService {
  url: string;

  constructor() {
    super();
    this.url = this.API_BASE_URL + "/api/front/me";
  }

  async me(): Promise<Result<Member, Error>> {
    return super.doGet<Member, Error>(this.url, "");
  }

  async leave(): Promise<Result<void, ServiceError>> {
    return super.doDelete<void, ServiceError>(this.url, "");
  }

  async edit(query: PutQuery, onSuccess: () => void, onFailure: (error: ErrorV2) => void, onError: () => void): Promise<void> {
    const body = JSON.stringify(query);
    const response = await fetch(this.url, {
      credentials: "include",
      method: "PUT",
      headers: this.headers,
      body,
    });
    if (response.ok) {
      onSuccess();
    } else if (response.status === 400) {
      const error = (await response.json()) as ErrorV2;
      await onFailure(error);
    } else {
      // 400エラーの場合はバリデーションエラーにしたい
      await onError();
    }
  }

  async changeEmail(
    formikValues: EmailChangeFormValues,
    onSuccess: () => void,
    onFailure: (error: ErrorV2) => void,
    onError: () => void
  ): Promise<void> {
    const body = JSON.stringify(formikValues);
    const response = await fetch(`${this.url}/email`, {
      credentials: "include",
      method: "POST",
      headers: this.headers,
      body,
    });
    if (response.ok) {
      onSuccess();
    } else if (response.status === 400) {
      const error = (await response.json()) as ErrorV2;
      onFailure(error);
    } else {
      onError();
    }
  }

  async changePassword(
    formikValues: PasswordChangeFormValues,
    onSuccess: () => void,
    onFailure: (error: ErrorV2) => void,
    onError: () => void
  ): Promise<void> {
    const body = JSON.stringify(formikValues);
    const response = await fetch(`${this.url}/password`, {
      credentials: "include",
      method: "POST",
      headers: this.headers,
      body,
    });
    if (response.ok) {
      onSuccess();
    } else if (response.status === 400) {
      const error = (await response.json()) as ErrorV2;
      onFailure(error);
    } else {
      onError();
    }
  }

  async registerBank(
    formikValues: BankRegisterFormValues,
    onSuccess: () => void,
    onFailure: (error: ErrorV2) => void,
    onError: () => void
  ): Promise<void> {
    const body = JSON.stringify(formikValues);
    const response = await fetch(`${this.url}/register_bank`, {
      credentials: "include",
      method: "POST",
      headers: this.headers,
      body,
    });
    if (response.ok) {
      onSuccess();
    } else if (response.status === 400) {
      const error = (await response.json()) as ErrorV2;
      onFailure(error);
    } else {
      onError();
    }
  }
  
  async monumentNameIds(
    projectCode: string,
    onSuccess: (monumentNameIds: string[]) => void,
    onFailure: (error: ErrorV2) => void,
    onError: () => void
  ): Promise<void> {
    const response = await fetch(`${this.url}/monument_name_ids?project_code=${projectCode}`, {
      credentials: "include",
      method: "GET",
      headers: this.headers,
    });
    if (response.ok) {
      onSuccess((await response.json()) as string[]);
    } else if (response.status === 401) {
      onSuccess([]); // do nothing
    } else if (response.status === 400) {
      const error = (await response.json()) as ErrorV2;
      onFailure(error);
    } else {
      onError();
    }
  }

  async getSupportComments(code: string, onSuccess: (supportComments: SupportComment[]) => void, onError: () => void): Promise<void> {
    const response = await fetch(`${this.url}/support_comments?code=${code}`, {
      credentials: "include",
      method: "GET",
      headers: this.headers,
    });
    if (response.ok) {
      const supportComments = (await response.json()) as SupportComment[];
      await onSuccess(supportComments);
    } else {
      await onError();
    }
  }

  async updateSupportComment(id: number, query: CommentInput): Promise<Result<void, ErrorV2>> {
    const body = JSON.stringify(query);
    return this.doPut(`${this.url}/support_comments/`, id.toString(), body);
  }

  async getTippingComment(code: string, onSuccess: (tippingComment: TippingComment | null) => void, onError: () => void): Promise<void> {
    const url = `${this.url}/tipping_comments?code=${code}`;
    const response = await fetch(url, {
      credentials: "include",
      method: "GET",
      headers: this.headers,
    });
    if (response.ok) {
      const tippingComment = (await response.json()) as TippingComment | null;
      onSuccess(tippingComment);
    } else {
      await onError();
    }
  }

  async updateTippingComment(id: number, query: UpdateTippingCommentQuery): Promise<Result<void, ErrorV2>> {
    const body = JSON.stringify(query);
    return this.doPut(`${this.url}/tipping_comments/`, id.toString(), body);
  }
}

export type UpdateTippingCommentQuery = {
  comment: string;
};

export interface EmailChangeFormValues extends FormikValues {
  email: string;
  email_confirmation: string;
}

export interface PasswordChangeFormValues extends FormikValues {
  password: string;
  new_password: string;
  new_password_confirmation: string;
}

export interface BankRegisterFormValues extends FormikValues {
  bank: string;
  branch: string;
  account_type: string;
  number: string;
  holder: string;
}
